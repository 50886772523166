import i18next from "i18next";
import { countBy, map } from "lodash";
import { DateTime } from "luxon";

import {
  ChoiceType,
  CustomFieldApiConfigurationType,
  CustomFieldDTO,
  CustomFieldType,
  ICustomField,
  ICustomFieldApiConfiguration,
  ICustomFieldCreateDTO,
  ICustomFieldDTO,
  ICustomFieldsControllerClient,
  ICustomFormAnswerDTO,
  PbdModule,
} from "@generatedCode/pbd-core/pbd-core-api";

import { DateTimeLuxonHelpers } from "../../../Helpers/DateTimeLuxonHelpers";
import StringHelpers from "../../../Helpers/StringHelpers";
import { CustomFieldResponseDTO } from "../../../Models/CustomForms/CustomFormAnswerIndexDTO";
import { IHaveCustomFields } from "../../Models/BaseClasses/IHaveCustomFields";
import { ValidationResult } from "../../Models/Shared/validation-result";
import { ValidationResultDescriber } from "../../Models/Shared/validation-result-describer";
import { WithWarnings } from "../../Models/Shared/with-warnings";
import { IGroupedResult } from "../Address/addressService";
import { ExportDTOService } from "../Export/exportDTOService";
import { ExportData } from "../Export/exportService";
import JsonHelpers from "../Json/jsonHelpers";

import { CustomFieldApiObjectName } from "./models/custom-field-api-object-name";
import { CustomFieldApiValue } from "./models/custom-field-api-value";
import { CustomFieldQueryParameter } from "./models/custom-field-query-parameter";

export default class CustomFieldService extends ExportDTOService<ICustomField> {
  customFieldsApi: ICustomFieldsControllerClient;
  constructor(_customFieldsApi: ICustomFieldsControllerClient) {
    super();
    this.customFieldsApi = _customFieldsApi;
  }

  async getAllCustomFieldsByModule(module: PbdModule) {
    const resp = await this.customFieldsApi.getCustomFieldsByModule(module);
    return resp.sort((a, b) => a.name.localeCompare(b.name));
  }

  static getCustomFieldStatus(all: ICustomField[], assigned: ICustomFieldDTO[]) {
    const customFieldsNotAssigned = [];
    for (const cf of all) {
      if (!assigned.map((x) => x.id).includes(cf.id)) {
        customFieldsNotAssigned.push(cf);
      }
    }
    const assignedAndDeleted = assigned.filter((x) => all.map((a) => a.id).includes(x.id));
    return {
      customFieldsNotAssigned,
      assignedAndDeleted,
      customFieldsNotAssignedRequired: customFieldsNotAssigned.filter((x) => x.isRequired),
    };
  }

  apiFieldConfigs: ICustomFieldApiConfiguration[] = [
    {
      type: CustomFieldApiConfigurationType.Persons,
      apiUrl: "/api/tenants",
      objectName: CustomFieldApiObjectName.Tenant,
    },
    {
      type: CustomFieldApiConfigurationType.Employees,
      apiUrl: "/api/tenants/?isEmployee=true",
      objectName: CustomFieldApiObjectName.Tenant,
    },
  ];

  mapExport(dto: ICustomField): ExportData {
    return { id: dto.id, title: dto.name };
  }

  getExportName(): string {
    return "Custom Fields";
  }

  static mapApiFieldValue(objectName: CustomFieldApiObjectName, value: number) {
    return JSON.stringify(new CustomFieldApiValue(value, objectName));
  }

  static getApiFieldValue(value: string) {
    return JsonHelpers.parse<CustomFieldApiValue>(value);
  }

  static filterByCustomFields<T extends IHaveCustomFields>(
    data: T[],
    customFieldQuery: (string | null)[] | undefined | null,
  ) {
    if (!customFieldQuery) return data;
    const qs: CustomFieldQueryParameter[] = [];
    customFieldQuery.forEach((x) => {
      if (x) {
        const split = x.split(":");
        if (!StringHelpers.isNullOrWhitespace(split[1])) {
          const q = { key: split[0], value: split[1] };
          qs.push(q);
        }
      }
    });
    if (qs.length == 0) return data;
    return CustomFieldService.#filterByCustomField(data, qs);
  }

  static #filterByCustomField<T extends IHaveCustomFields>(data: T[], qs: CustomFieldQueryParameter[]) {
    let filtered: T[] = [];
    const keys = qs.map((x) => x.key);
    for (const element of data) {
      const includes = element.customFields?.find((x) => keys.includes(x.id));
      if (includes) {
        filtered = CustomFieldService.filterRegex(element, keys, qs, filtered);
      }
    }
    return filtered;
  }

  static filterRegex<T extends IHaveCustomFields>(
    element: T,
    keys: string[],
    qs: CustomFieldQueryParameter[],
    filtered: T[],
  ) {
    let boolForPush = true;
    for (const key of keys) {
      const assignedCustomField = element.customFields?.find((x) => x.id == key);
      const qp = qs.find((x) => x.key == key);
      if (assignedCustomField?.value) {
        if (qp && qp.value != "") {
          const regex = new RegExp(qp.value, "i");
          if (!regex.test(assignedCustomField.value)) {
            boolForPush = false;
          }
        }
      } else {
        boolForPush = false;
      }
    }
    if (boolForPush) filtered.push(element);
    return filtered;
  }

  static getWarning<T extends IHaveCustomFields & { deadline?: DateTime }>(
    itemNotMapped: T,
    customFields: ICustomField[],
  ) {
    const item = itemNotMapped as WithWarnings<T>;
    item.warnings = [];
    if (item.deadline && DateTimeLuxonHelpers.inPast(item.deadline)) {
      item.warnings.push(ValidationResultDescriber.deadlineExpired());
    }

    const requiredCustomFields = customFields.filter((x) => x.isRequired);
    for (const cf of requiredCustomFields) {
      if (item.customFields?.find((x) => x.id === cf.id) === undefined) {
        item.warnings.push(ValidationResultDescriber.requiredCustomFieldMissing());
        break;
      }
    }
    return item;
  }

  static getWarnings<T extends IHaveCustomFields & { deadline?: DateTime }>(array: T[], customFields: ICustomField[]) {
    for (const elem of array) {
      CustomFieldService.getWarning(elem, customFields);
    }
    return array;
  }

  static addDefaultValuesBeforeCreate(dto: ICustomFieldCreateDTO) {
    switch (dto.type) {
      case CustomFieldType.Choices:
        dto.choices = ["Option 1", "Option 2"];
        break;
    }
  }

  static get excludedCustomFieldTypes() {
    return [CustomFieldType.Paragraph];
  }

  static get customFieldTypes() {
    return Object.values(CustomFieldType).filter((x) => ![CustomFieldType.Api].includes(x));
  }

  static mapCustomFields(items: ICustomField[], itemToUpdate?: Pick<ICustomFormAnswerDTO, "formFields">) {
    const obj: CustomFieldDTO[] = [];
    for (const element of items) {
      if (element.type == CustomFieldType.Choices && element.customFieldInfo?.choiceType == ChoiceType.Multi) {
        const textValue = itemToUpdate?.formFields.find((x) => x.id == element.id)?.value ?? "";
        let arrayVal: string[] = [];
        if (textValue && JsonHelpers.isJson(textValue)) {
          arrayVal = JsonHelpers.parse(textValue);
        }
        obj.push(
          new CustomFieldDTO({
            id: element.id,
            //@ts-expect-error TODO: Fix this with a dedicated view model for the form.
            value: arrayVal,
          }),
        );
      } else {
        obj.push(
          new CustomFieldDTO({
            id: element.id,
            value: itemToUpdate?.formFields.find((x) => x.id == element.id)?.value ?? "",
          }),
        );
      }
    }
    return obj;
  }

  static mapCustomField(item: ICustomField, valueField?: ICustomFieldDTO) {
    if (item.type == CustomFieldType.Choices && item.customFieldInfo?.choiceType == ChoiceType.Multi) {
      const textValue = valueField?.value ?? "";
      let arrayVal: string[] = [];
      if (textValue && JsonHelpers.isJson(textValue)) {
        arrayVal = JsonHelpers.parse(textValue);
        return arrayVal;
      }
    }
    return valueField?.value;
  }

  /**
   * All custom field values must be strings
   */
  static sanitizeCustomFields(dto: ICustomFieldDTO[]) {
    for (const cf of dto) {
      if (cf.value && Array.isArray(cf.value)) {
        cf.value = JSON.stringify(cf.value);
      } else if (cf.value && typeof cf.value == "number") {
        // @ts-expect-error TODO: Improve typing. Currently the value here can be of type number
        cf.value = cf.value.toString();
      }
    }
    return dto;
  }

  static formatCustomFieldValue(content: ICustomFieldDTO, customField: ICustomField) {
    let returnValue = content.value;
    try {
      if (customField.type == CustomFieldType.Choices && content.value && JsonHelpers.isJson(content.value)) {
        returnValue = JsonHelpers.parse<string[]>(content.value).join();
        returnValue = returnValue ? returnValue : i18next.t("Not set");
      } else if (customField.type == CustomFieldType.Date) {
        returnValue = content.value
          ? DateTimeLuxonHelpers.convertUtcToDate(DateTime.fromISO(content.value))
          : i18next.t("Missing value");
      }
    } catch (ex) {
      console.log(ex);
    }
    return returnValue;
  }

  static validateFieldConfiguration(item: ICustomField): ValidationResult[] {
    const result: ValidationResult[] = [];
    if (item.type == CustomFieldType.Choices && (!item.options || item.options.length == 0)) {
      result.push(ValidationResultDescriber.missingOptions());
    }
    return result;
  }
  static sortArr(arr: AnsweredCustomFieldsDTO[]) {
    arr = arr.sort((a, b) => {
      if (a.field != undefined && b.field != undefined) {
        return a.field.name.localeCompare(b.field.name);
      }
      return -1;
    });
    return arr;
  }

  static getFilteredAnsweredCustomFieldsByText(fields: ICustomField[], answers: ICustomFieldDTO[]) {
    const arr: AnsweredCustomFieldsDTO[] = [];
    if (answers.length > 0) {
      answers.forEach((x) => {
        arr.push({ answer: x, field: fields.find((y) => y.id == x.id) });
      });
    }
    const sortedArr = CustomFieldService.sortArr(arr);
    return sortedArr;
  }

  static getDecomposedCustomFieldChoiceResponses(field: ICustomField, responses: CustomFieldResponseDTO[]) {
    let decomposedArr: CustomFieldResponseDTO[] = [];

    if (field.customFieldInfo?.choiceType == ChoiceType.Multi) {
      responses.forEach((r) => {
        const returnValue = r.value && JsonHelpers.isJson(r.value) && JsonHelpers.parse<string[]>(r.value).join();
        if (returnValue) {
          const choices = returnValue.split(",");
          if (choices.length > 0) {
            choices.forEach((c) => {
              decomposedArr.push({ ...r, value: c });
            });
          }
        } else {
          decomposedArr.push({ ...r, value: i18next.t("Not set") });
        }
      });
    } else {
      decomposedArr = responses;
    }

    return decomposedArr;
  }

  static groupByResponseValues(responses: CustomFieldResponseDTO[]) {
    const grouped = countBy(responses, (x) => x.value);
    const data: IGroupedResult[] = map(grouped, function (value, key) {
      return {
        key: StringHelpers.isNullOrWhitespace(key) ? i18next.t("No value") : key,
        count: value,
        percentage: value / responses.length,
      };
    }).sort((a, b) => b.count - a.count);
    return data;
  }

  static handleCustomFieldSelectValue(value: string, name: string, query: string[] | undefined) {
    const newCustomFields = query;
    if (value != "" && newCustomFields) {
      const indexOfTarget = newCustomFields.findIndex((x) => x.startsWith(name));
      const targetSplitForNewValue = newCustomFields[indexOfTarget].split(":")[0];
      newCustomFields[indexOfTarget] = `${targetSplitForNewValue}:${value}`;
    } else {
      if (newCustomFields) {
        const indexOfTarget = newCustomFields.findIndex((x) => x.startsWith(name));
        const targetSplitForNewValue = newCustomFields[indexOfTarget].split(":")[0];
        newCustomFields[indexOfTarget] = targetSplitForNewValue;
      }
    }
    return newCustomFields;
  }

  static changeCustomFieldIds(customFieldIds: string[], query?: string[]): string[] {
    if (customFieldIds.length == 0) return [];
    const queryNew = [];
    for (const id of customFieldIds) {
      const connected = (query ?? []).find((x) => x.startsWith(`${id}:`));
      if (connected == undefined) {
        queryNew.push(id);
      } else {
        queryNew.push(connected);
      }
    }
    return queryNew;
  }

  static getCustomFieldIdsFromQuery(value?: string | string[]): string[] | undefined {
    if (!value) return undefined;
    if (Array.isArray(value)) {
      return value.map((x) => x.split(":")[0]);
    }
    return [value.split(":")[0]];
  }

  static getCustomFieldKeyValuesFromQuery(value?: string | string[]): Record<string, string> | undefined {
    if (!value) return undefined;
    if (Array.isArray(value)) {
      return value.reduce<Record<string, string>>(
        (acc, item) => ({
          ...acc,
          [item.split(":")[0]]: item.split(":")[1],
        }),
        {},
      );
    }
    return { [value.split(":")[0]]: value.split(":")[1] };
  }
}

export interface AnsweredCustomFieldsDTO {
  answer: ICustomFieldDTO;
  field?: ICustomField;
}
